import { useEffect, useState } from 'react'
import style from './HomePanel.module.scss'
import QuickLink from '@/features/common/components/QuickLink'
import LoadingBar from '@/features/common/components/LoadingBar'
import Contents from '@/features/home/components/Contents'
import CustomerService from '@/features/home/components/CustomerService'
import KeyVisual from '@/features/home/components/KeyVisual'
import Reviews from '@/features/home/components/Reviews'

function HomePanel() {
  const [mounted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  if (!mounted) return <LoadingBar />

  return (
    <main className={style.container}>
      <KeyVisual />
      <Contents />
      <Reviews />
      <CustomerService />
      <QuickLink />
    </main>
  )
}

export default HomePanel

import { useScrollFadeIn } from '@/features/common/hooks/useScrollFadeIn'

export function useFadeInAnimation(throttle = 0.7) {
  const animationFadeInUp = {}
  const animationFadeInDown = {}
  const animationFadeInLeft = {}
  const animationFadeInRight = {}

  for (let i = 0; i <= 100; i++) {
    animationFadeInUp[i] = useScrollFadeIn('up', 1, i / 10, throttle)
    animationFadeInDown[i] = useScrollFadeIn('down', 1, i / 10, throttle)
    animationFadeInLeft[i] = useScrollFadeIn('left', 1, i / 10, throttle)
    animationFadeInRight[i] = useScrollFadeIn('right', 1, i / 10, throttle)
  }

  const length = Object.keys(animationFadeInUp).length

  return { animationFadeInUp, animationFadeInDown, animationFadeInLeft, animationFadeInRight, length }
}

import { useRouter } from 'next/router'

export function useCustomRouter<T>() {
  const router = useRouter()
  const { query } = router

  // 뒤로 가기
  const handleBack = () => router.back()

  // 특정 경로로 이동
  const handlePush = (path: string) => router.push(path)

  // 현재 경로로 이동 (새로고침)
  const handleReload = () => router.reload()

  // 404 페이지로 이동
  const handleNotFound = () => router.push('/404')

  // 다른 도메인으로 이동
  const handleExternalLink = (url: string | URL) => window.open(url, '_blank')

  return {
    ...router,
    query: query as T,
    handleBack,
    handlePush,
    handleReload,
    handleNotFound,
    handleExternalLink,
  }
}

import Image from 'next/image'
import { useMediaQuery } from 'react-responsive'
import style from './ReviewArticle.module.scss'
import { INTERVIEW_PATH } from '@/constants/routes'
import { useCustomRouter } from '@/features/common/hooks/useCustomRouter'
import { useFadeInAnimation } from '@/features/common/hooks/useFadeInAnimation'
import ArticleModel from '@/features/common/models/articleModel'
import { sendCustomEvent } from '@/utils/GTM'

interface IReviewArticle {
  articleModel: ArticleModel
}

function ReviewArticle({ articleModel }: IReviewArticle) {
  const isMobile = useMediaQuery({ minWidth: 1193 })
  const { handlePush } = useCustomRouter()
  const { animationFadeInUp: animationFadeInUpWithoutThrottle } = useFadeInAnimation(0)

  const handleChangeRoute = (url: string) => {
    sendCustomEvent({
      event: 'click',
      category: '내부 컨텐츠 보기 클릭',
      label: `review - ${articleModel.title}`,
    })
    handlePush(url)
  }

  return (
    <article
      {...(isMobile ? animationFadeInUpWithoutThrottle[articleModel.id] : {})}
      className={style.container}
      onClick={() => handleChangeRoute(`${INTERVIEW_PATH}/${articleModel.id}`)}
    >
      <div>
        <Image src={articleModel.thumbnail} alt={`review image ${articleModel.id}`} width={375} height={375} />
      </div>
      <h3>{articleModel.title}</h3>
      <p>
        <span dangerouslySetInnerHTML={{ __html: articleModel.description || '' }}></span>
      </p>
    </article>
  )
}

export default ReviewArticle

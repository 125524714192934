export function useGetCfDocument() {
  const getUrlDocument = async (url: string) => {
    const html = await (await fetch(url)).text() // html as text
    const doc = new DOMParser().parseFromString(html, 'text/html')

    return doc?.getElementsByTagName('html')[0]?.innerHTML
  }

  return {
    getUrlDocument,
  }
}

import { useMediaQuery } from 'react-responsive'

export function useCustomMediaQuery() {
  // Block
  function Desktop({ children }) {
    const isDesktop = useMediaQuery({ minWidth: 1281 })
    return isDesktop ? children : null
  }

  function Tablet({ children }) {
    const isTablet = useMediaQuery({ minWidth: 1194, maxWidth: 1280 })
    return isTablet ? children : null
  }

  function UpToTablet({ children }) {
    const isUpToMobile = useMediaQuery({ minWidth: 1194 })
    return isUpToMobile ? children : null
  }

  function Mobile({ children }) {
    const isMobile = useMediaQuery({ maxWidth: 1193 })
    return isMobile ? children : null
  }

  function UpToMobile({ children }) {
    const isUpToMobile = useMediaQuery({ maxWidth: 1280 })
    return isUpToMobile ? children : null
  }

  // boolean
  function isUpToMobile() {
    return useMediaQuery({ maxWidth: 1280 })
  }

  function isMobile() {
    return useMediaQuery({ maxWidth: 1193 })
  }

  return {
    Desktop,
    Tablet,
    Mobile,
    UpToTablet,
    UpToMobile,
    isUpToMobile,
    isMobile,
  }
}
